import { colourLookup1, colourLookup2, LogoProps } from './LogoIcon'

export const LogoWatermark = ({ colour, className, width = 160 }: LogoProps) => {
  const colourClass1 = colourLookup1[colour]
  const colourClass2 = colourLookup2[colour]
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1120 320" className={className} width={width}>
      <g>
        <path
          className={colourClass2}
          d="m263.76,69.49l-67.88,67.88-21.95-21.95c-24.87-24.87-65.39-25.82-90.58-1.27-25.6,24.94-25.8,65.91-.6,91.11l22.63,22.63,22.63,22.63,22.63-22.63,45.25-45.25,21.95,21.95c24.87,24.87,65.39,25.82,90.58,1.27,25.6-24.94,25.8-65.91.6-91.11l-45.25-45.25ZM128,205.25l-22.63-22.63c-12.5-12.5-12.5-32.76,0-45.25,12.5-12.5,32.76-12.5,45.25,0l22.63,22.63-45.25,45.25Zm112.67-23.1l-22.16-22.16,45.25-45.25,22.63,22.63c12.71,12.71,12.49,33.45-.64,45.88-12.66,11.99-32.75,11.23-45.08-1.1Z"
        />
        <rect
          className={colourClass1}
          x="202.51"
          y="166.63"
          width="32"
          height="32"
          transform="translate(193.14 -101.02) rotate(45)"
        />
      </g>
      <g>
        <path
          className={colourClass2}
          d="m384,95.33h50.78c28.97,0,48.4,17.6,48.4,43.82s-19.43,43.82-48.4,43.82h-21.45v40.7h-29.33V95.33Zm51.7,61.97c10.82,0,18.15-7.34,18.15-18.15s-7.33-18.15-18.15-18.15h-22.37v36.3h22.37Z"
        />
        <path
          className={colourClass2}
          d="m493.27,132h28.42v9.17c5.68-7.52,14.12-11.37,24.75-11.37,2.57,0,4.95.18,7.52.55v25.11c-2.38-.37-4.77-.55-7.34-.55-15.22,0-24.93,8.07-24.93,20.72v48.03h-28.42v-91.67Z"
        />
        <path
          className={colourClass2}
          d="m558.55,198.18c0-15.58,9.9-24.75,28.78-27.68l28.42-4.4v-1.83c0-7.52-5.87-10.63-15.03-10.63s-16.13,5.13-20.35,12.83l-21.63-11.37c6.05-15.58,21.63-25.3,41.98-25.3,26.03,0,43.45,14.3,43.45,34.47v59.4h-28.05v-6.23c-6.05,5.32-14.48,8.43-24.75,8.43-17.05,0-32.82-10.26-32.82-27.68Zm39.42,6.6c9.72-.18,17.78-5.32,17.78-14.85v-4.03l-22.36,3.48c-4.4.73-7.34,3.12-7.34,7.33,0,5.5,5.13,8.25,11.92,8.07Z"
        />
        <path
          className={colourClass2}
          d="m654.25,177.83c0-28.23,20.72-48.03,48.4-48.03,23.65,0,40.7,13.93,44.73,30.62l-25.3,6.78c-1.84-5.32-8.07-11.73-18.7-11.73-11.92,0-20.72,7.7-20.72,22.37s8.8,22.37,20.72,22.37c10.63,0,16.87-6.42,18.7-11.73l25.3,6.78c-4.03,16.68-21.08,30.61-44.73,30.61-27.87,0-48.4-19.8-48.4-48.03Z"
        />
        <path
          className={colourClass2}
          d="m766.64,192.68v-36.85h-16.5v-23.83h7.33c6.78,0,9.17-4.4,9.17-17.23v-7.33h28.42v24.57h20.72v23.83h-20.72v34.83c0,6.97,3.67,10.82,10.27,10.82,3.85,0,8.07-.73,11.55-1.84l3.67,22c-5.68,2.02-12.28,3.12-19.07,3.12-20.9,0-34.83-12.83-34.83-32.08Z"
        />
        <path
          className={colourClass2}
          d="m843.82,88c9.9,0,17.78,8.07,17.78,17.6s-7.88,17.6-17.78,17.6-17.78-8.07-17.78-17.6,7.88-17.6,17.78-17.6Zm-14.12,44h28.42v91.67h-28.42v-91.67Z"
        />
      </g>
    </svg>
  )
}
