import { createContext, PropsWithChildren, useContext, useState } from 'react'

interface TreatmentGuideModalTypes {
  isOpen: boolean
  treatmentGuideId?: string
}

const TreatmentGuideContext = createContext<
  | {
      treatmentGuideModalData: TreatmentGuideModalTypes
      setTreatmentGuideModalData: (treatmentGuideModalData: TreatmentGuideModalTypes) => void
    }
  | undefined
>(undefined)

export const TreatmentGuideModalProvider = ({ children }: PropsWithChildren) => {
  const [treatmentGuideModalData, setTreatmentGuideModalData] = useState<TreatmentGuideModalTypes>({
    treatmentGuideId: '',
    isOpen: false,
  })

  return (
    <TreatmentGuideContext.Provider value={{ treatmentGuideModalData, setTreatmentGuideModalData }}>
      {children}
    </TreatmentGuideContext.Provider>
  )
}

export const useTreatmentGuideContext = () => {
  const context = useContext(TreatmentGuideContext)
  if (!context) {
    throw new Error('TreatmentGuideContext must be used within a TreatmentGuideModalProvider component')
  }
  return context
}
