import { memo, ReactNode } from 'react'
import { Icon } from '@library/components/atoms'
import { IconName } from '@library/components/atoms/Icon/utils'
import { Paragraph } from '@library/components/atoms/Paragraph/Paragraph'
import { Size } from '@library/types'
import { transitionClasses } from '@library/utils'
import clsx from 'clsx'

import { PatientAction } from '@shared/data/types'

import {
  baseClasses,
  disabledClasses,
  errorClasses,
  getAppearanceClasses,
  getSizeClasses,
  getTemplateData,
  getTextSize,
  getTreatmentAppearance,
} from './utils'

export type TagAppearance = 'subtle' | 'outline' | 'oat' | 'sage' | 'duckEgg'
export interface TagProps {
  index?: number
  template?: PatientAction | 'OUTLINE'
  text?: string | ReactNode
  icon?: IconName
  appearance?: TagAppearance
  disabled?: boolean
  size?: Extract<Size, 'small' | 'medium' | 'large'>
  error?: boolean
  tooltip?: ReactNode
  className?: string
  handleRemoveTag?: (index: number) => void
  handleOnClick?: () => void
}

export const Tag = memo(
  ({
    index,
    template,
    tooltip,
    text,
    icon,
    disabled,
    size = 'medium',
    error,
    appearance,
    className,
    handleRemoveTag,
    handleOnClick,
  }: TagProps) => {
    const sizeClasses = getSizeClasses(size)
    const appearanceClasses = getAppearanceClasses(appearance) + transitionClasses
    const templateData = getTemplateData(template)
    const treatmentStyle = getTreatmentAppearance(text)
    const testId = `tag-${text}`
    return (
      <div
        onClick={handleOnClick}
        data-testid={testId}
        className={clsx(
          baseClasses,
          sizeClasses,
          !treatmentStyle && appearance && appearanceClasses,
          treatmentStyle,
          template && templateData?.baseClasses,
          disabled && disabledClasses,
          error && errorClasses,
          className,
        )}
      >
        <Paragraph
          data-testid="tag-text"
          className={clsx(
            template && templateData?.textClasses,
            disabled && '!text-foregroundNeutralDisabled',
            'text-nowrap',
          )}
          size={getTextSize(size)}
        >
          <span className="flex gap-1 break-keep">
            {templateData?.text ? templateData?.text : text}
            {tooltip}
          </span>
        </Paragraph>

        {icon && (
          <span onClick={() => handleRemoveTag!(index!)}>
            <Icon
              testId={`tag-icon-${icon}`}
              color={template ? templateData?.iconColor : 'text-alphaDark20' || 'text-current'}
              iconName={icon}
              size={'small'}
              className={clsx(
                '!stroke-[1px] cursor-pointer',
                error && 'text-foregroundErrorPrimary !hover:text-foregroundErrorPrimary',
                disabled
                  ? '!hover:text-alphaDark20 !active:text-alphaDark20 !cursor-not-allowed'
                  : 'active:text-alphaDark60 hover:text-alphaDark40',
              )}
            />
          </span>
        )}
      </div>
    )
  },
)

Tag.displayName = 'Tag'
