import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { UserPractice, UserRole } from '@shared/data/types'

interface PracticeStore {
  isSharedUser?: boolean
  practiceUserRole?: UserRole | undefined
  memberPractices: UserPractice[]
  selectedPracticeId: string
}

interface PracticeStoreActions {
  setIsSharedUser: (shared: boolean) => void
  setPracticeUserRole: (role: UserRole) => void
  setMemberPractices: (practices: UserPractice[]) => void
  setSelectedPractice: (practiceId: string) => void
}

export const usePracticeStore = create<PracticeStore & PracticeStoreActions>()(
  devtools(
    (set) => ({
      practiceUserRole: undefined,
      memberPractices: [],
      selectedPracticeId: '',
      isSharedUser: false,
      setIsSharedUser: (shared: boolean) => set({ isSharedUser: shared }),
      setPracticeUserRole: (role: UserRole) => set({ practiceUserRole: role }),
      setSelectedPractice: (practiceId: string) => set({ selectedPracticeId: practiceId }),
      setMemberPractices: (memberPractices: UserPractice[]) => set({ memberPractices }),
    }),
    {
      name: 'practice-store',
    },
  ),
)
