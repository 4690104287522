import { Size } from '@library/types'

export const getAccordionSpacingSizes = (size: Size) => {
  switch (size) {
    case 'medium':
      return 'py-5 gap-y-2'
    case 'large':
      return 'py-8 gap-y-3'
    default:
      return 'py-5 gap-y-2'
  }
}
export const getAccordionTextSize = (size: Size) => {
  switch (size) {
    case 'large':
      return 'xLarge'
    case 'xSmall':
      return 'small'
    default:
      return size
  }
}
