import { useEffect, useRef } from 'react'

type EventType = 'mousedown' | 'mouseup' | 'touchstart' | 'touchend'

export default function useEventListener(
  eventType: EventType,
  callback: (e: MouseEvent | TouchEvent) => void,
  element = window,
) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (element == null) return
    const handler = (e: MouseEvent | TouchEvent) => callbackRef.current(e)
    element.addEventListener(eventType, handler)

    return () => element.removeEventListener(eventType, handler)
  }, [eventType, element])
}
