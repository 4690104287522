import { memo } from 'react'
import { IconName } from '@library/components/atoms/Icon/utils'
import clsx from 'clsx'

import { AccordionItem } from '../AccordionItem'

const accordionWrapperClass = `
  w-full 
  flex 
  flex-col 
`
export interface AccordionItemType {
  title: string
  answer: string
}

export interface AccordionIcon {
  close: IconName
  open: IconName
}
export interface AccordionProps {
  size?: 'medium' | 'large'
  items: AccordionItemType[]
  icons?: AccordionIcon
}

export const Accordion = memo(
  ({
    size = 'medium',
    items,
    icons = {
      open: 'chevron-down',
      close: 'chevron-up',
    },
  }: AccordionProps) => {
    return (
      <div className={clsx(accordionWrapperClass)}>
        {items &&
          items.length &&
          items.map((item) => <AccordionItem key={item.title} item={item} icons={icons} size={size} />)}
      </div>
    )
  },
)

Accordion.displayName = 'Accordion'
