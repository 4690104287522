import { patientFetch, practiceFetch } from '@shared/utils/fetch'
import { newApiBaseUrl, newApiUrls } from '@shared/utils/urls'

import { IntercomData } from '../../../intercom'

export class IntercomService {
  private intercomUrl: string | undefined
  private intercomAppId: string | undefined
  private intercomLaunched: boolean
  private intercomBooted: boolean

  constructor() {
    this.intercomUrl = import.meta.env.VITE_INTERCOM_APP_URL || undefined
    this.intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID || undefined
    this.intercomLaunched = false
    this.intercomBooted = false

    if (this.intercomAppId) {
      const intercom = (...intercomArgs: unknown[]) => {
        intercom.c(intercomArgs)
      }
      const q: unknown[] = []
      intercom.q = q
      intercom.c = (args: unknown[]) => {
        q.push(args)
      }
      window.Intercom = intercom
    }
  }

  public async bootIntercom(
    firstName: string,
    lastName: string,
    email: string,
    userType: 'Patient' | 'Practitioner',
    practiceName?: string,
  ): Promise<void> {
    if (window.Intercom && !this.intercomBooted && this.intercomAppId && this.intercomUrl) {
      const fetch = userType === 'Patient' ? patientFetch : practiceFetch
      const response = await fetch({
        baseURL: newApiBaseUrl,
        url: `${newApiUrls.auth}${newApiUrls.intercom}`,
        method: 'GET',
      })
      const intercomData: IntercomData = {
        api_base: this.intercomUrl,
        app_id: this.intercomAppId,
        name: `${firstName} ${lastName}`,
        email,
        patient: userType === 'Patient',
        user_hash: response.data.hash,
        created_at: Date.now(),
      }

      if (practiceName) {
        intercomData['Practice name'] = practiceName
      }

      window.Intercom('boot', intercomData)
      this.intercomBooted = true

      if (!this.intercomLaunched) {
        const script = document.createElement('script')
        script.src = `https://widget.intercom.io/widget/${this.intercomAppId}`
        script.type = 'text/javascript'
        script.async = true
        document.body.appendChild(script)
        this.intercomLaunched = true
      }
    }
  }

  public shutdownIntercom() {
    if (window.Intercom) {
      window.Intercom('shutdown')
      this.intercomBooted = false
    }
  }
}
