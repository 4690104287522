import { memo } from 'react'
import { Badge, Icon } from '@library/components/atoms'
import { IconName } from '@library/components/atoms/Icon/utils'
import { Size } from '@library/types'
import { getTextSize } from '@library/utils'
import clsx from 'clsx'

export interface BaseHelperProps extends React.HTMLAttributes<HTMLSpanElement> {
  helperIcon?: IconName
  badgeText?: string
  text: string
  size: Size
  appearance: 'primary' | 'secondary' | 'tertiary'
  disabled?: boolean
}

export const BaseHelper = memo(
  ({ appearance, size, text, helperIcon, badgeText, disabled = false, ...props }: BaseHelperProps) => {
    let colorClass
    switch (appearance) {
      case 'primary':
        colorClass = 'text-foregroundNeutralPrimary'
        break
      case 'secondary':
        colorClass = 'text-foregroundNeutralSecondary'
        break
      case 'tertiary':
        colorClass = 'text-foregroundNeutralTertiary'
        break
    }
    return (
      <span
        {...props}
        className={clsx(
          'flex gap-2 font-normal',
          getTextSize(size),
          disabled ? '!text-foregroundNeutralDisabled' : colorClass,
        )}
      >
        {text && <span>{text}</span>}
        {/* If the size of the helper text is xSmall or small then the icon size is xSmall otherwise it is small */}
        {helperIcon && <Icon size={['xSmall', 'small'].includes(size) ? 'xSmall' : 'small'} iconName={helperIcon} />}
        {/* Badge component size is always xSmall */}
        {badgeText && <Badge className="self-center" text={badgeText} size="xSmall" />}
      </span>
    )
  },
)

BaseHelper.displayName = 'BaseHelper'
