import { memo, useState } from 'react'
import { getTabClasses } from '@library/components/molecules/TabGroup/utils'
import { TabItem, TabItemProps } from '@library/components/molecules/TabItem'
import clsx from 'clsx'

const TabGroupWrapperClass = `
  flex
  flex-row
  items-center
  justify-start
`

export interface TabGroupProps {
  size?: 'small' | 'medium' | 'large'
  tabs: Omit<TabItemProps, 'isSelected' | 'handleKeyDown' | 'index'>[]
}

export const TabGroup = memo(({ size = 'medium', tabs }: TabGroupProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, index: number) => {
    if (event.key === ' ') {
      event.preventDefault()
    }
    if (event.key === 'Enter') {
      setSelectedIndex(index)
    }
  }

  return (
    <div className={clsx(TabGroupWrapperClass, getTabClasses(size))}>
      {tabs &&
        tabs.length &&
        tabs.map(({ handleOnClick, ...props }, index) => (
          <TabItem
            index={index}
            key={index}
            size={size}
            isSelected={selectedIndex === index}
            handleKeyDown={(event) => handleKeyDown(event, index)}
            handleOnClick={(event) => {
              if (handleOnClick) handleOnClick(event)
              setSelectedIndex(index)
            }}
            {...props}
          />
        ))}
    </div>
  )
})

TabGroup.displayName = 'TabGroup'
