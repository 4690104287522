import { memo, ReactNode } from 'react'
import { Badge, BadgeAppearance, Icon } from '@library/components/atoms'
import { IconName } from '@library/components/atoms/Icon/utils'
import { Size } from '@library/types'
import { getTextSize } from '@library/utils'
import clsx from 'clsx'

export interface BaseLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  disabled?: boolean
  strong?: boolean
  labelIcon?: IconName
  badgeText?: string
  badgeAppearance?: BadgeAppearance
  tooltip?: ReactNode
  text: string
  size: Size
}

export const BaseLabel = memo(
  ({
    text,
    size = 'medium',
    disabled,
    labelIcon,
    badgeText,
    strong = false,
    badgeAppearance,
    tooltip,
    className,
    ...props
  }: BaseLabelProps) => {
    return (
      <span
        {...props}
        className={clsx(
          'flex gap-2',
          getTextSize(size),
          strong ? 'font-medium' : 'font-normal',
          disabled ? 'text-foregroundNeutralDisabled' : 'text-foregroundNeutralPrimary',
          className,
        )}
      >
        {text && <span>{text}</span>}
        {tooltip && tooltip}
        {/* If the size of the helper text is xSmall or small then the icon size is xSmall otherwise it is small */}
        {labelIcon && (
          <Icon
            color="text-current"
            size={['xSmall', 'small'].includes(size) ? 'xSmall' : 'small'}
            iconName={labelIcon}
          />
        )}
        {/* Badge component size is always xSmall */}
        {badgeText && <Badge className="self-center" appearance={badgeAppearance} text={badgeText} size="xSmall" />}
      </span>
    )
  },
)

BaseLabel.displayName = 'BaseLabel'
