import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { patientFetch as fetch } from '@shared/utils/fetch'
import { apiUrls } from '@shared/utils/urls'

interface PracticeDetail {
  id: string
  name: string
  email: string
  phone: string
  postcode: string
  city: string
  country: string | null
  street: string
  logoUrl: string
  offersFinance: boolean
  financeOnly?: boolean
  demo?: boolean
}

type PatientDetail = {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  practices: PracticeDetail[]
  smileQuizCompleted: boolean
  feedbackQuizCompleted: boolean
  isLoading: boolean
  isError: boolean
  errorMessage: string
  smileQuizSkipped?: boolean
  trackingId: string
}
type PatientPracticeDetailAction = {
  getPatientPracticeDetails: () => void
  setDetailState: (data: Partial<PatientDetail>) => void
}

export const usePatientPracticeStore = create<PatientDetail & PatientPracticeDetailAction>()(
  devtools(
    (set, get) => ({
      firstName: '',
      lastName: '',
      id: '',
      email: '',
      phone: '',
      practices: [],
      smileQuizCompleted: false,
      feedbackQuizCompleted: false,
      isLoading: false,
      isError: false,
      errorMessage: '',
      trackingId: '',
      getPatientPracticeDetails: async () => {
        get().setDetailState({
          isLoading: true,
        })
        try {
          const data = await fetch({
            url: apiUrls.patientUserDetails,
            method: 'GET',
          })
          const {
            firstName,
            lastName,
            id,
            email,
            phone,
            practices,
            smileQuizCompleted,
            feedbackQuizCompleted,
            smileQuizSkipped,
            trackingId,
          } = data
          get().setDetailState({
            isLoading: false,
            isError: false,
            errorMessage: '',
            id,
            firstName,
            lastName,
            email,
            phone,
            practices,
            smileQuizCompleted,
            feedbackQuizCompleted,
            smileQuizSkipped,
            trackingId,
          })
        } catch (error) {
          get().setDetailState({
            isLoading: false,
            isError: true,
            errorMessage: 'Couldnt fetch patient-practice information',
          })
          // eslint-disable-next-line no-console
          console.error('Couldnt fetch patient-practice information', error)
          return error
        }
      },
      setDetailState: (data) => {
        set(data)
      },
    }),
    {
      name: 'patientPracticeDetails',
    },
  ),
)
