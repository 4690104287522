import { createElement, ReactNode } from 'react'
import clsx from 'clsx'

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'd1'
  | 'd2'
  | 'd3'
  | 'd4'
  | 'b24'
  | 'b20'
  | 'b16'
  | 'b14'
  | 'b12'
  | 'l24'
  | 'l20'
  | 'l16'
  | 'l14'
  | 'l12'

export type TypographyProps = {
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'label' | 'span' | 'li' | 'div'
  variant: TypographyVariant
  children?: ReactNode
  className?: string
  htmlFor?: string
}

export const Typography = ({ element, variant, children, className, ...props }: TypographyProps) => {
  const h1Styles =
    'text-[3.5rem] leading-[3.5rem] font-medium tracking-normal md:text-[4rem] md:leading-[4rem] xl:text-[6rem] xl:leading-[6rem]'

  const h2Styles =
    'text-[2.5rem] leading-[2.5rem] font-medium tracking-normal md:text-[3rem] md:leading-[3rem] xl:text-[4rem] xl:leading-[4rem]'

  const h3Styles =
    'text-[2rem] leading-[2.25rem] font-medium tracking-normal md:text-[2.5rem] md:leading-[2.5rem] xl:text-[3rem] xl:leading-[3.5rem]'

  const h4Styles = 'text-[1.75rem] leading-[2rem] tracking-normal font-medium md:text-[2rem] md:leading-[2.25rem]'

  const h5Styles = 'text-[1.5rem] leading-[2rem] tracking-[.02em] font-medium'

  const h6Styles = 'text-[1.25rem] leading-[1.75rem] tracking-[.02em] font-medium'

  const d1Styles =
    'text-[4rem] leading-[4.5rem] tracking-[0%] font-normal md:text-[5rem] md:leading-[5.5rem] xl:text-[6rem] xl:leading-[6.25rem]'

  const d2Styles = 'text-[3rem] leading-[3.5rem] tracking-[0%] font-normal md:text-[4rem] md:leading-[4.5rem]'

  const d3Styles = 'text-[2.5rem] leading-[3rem] tracking-[0%] font-normal xl:text-[3rem] xl:leading-[3.5rem]'

  const d4Styles = 'text-[2rem] leading-[2.25rem] tracking-[0%] font-normal'

  const b24Styles = 'text-[1.5rem] leading-[1.75rem] font-normal tracking-normal'

  const b20Styles = 'text-[1.25rem] leading-[1.5rem] font-normal tracking-[.02em]'

  const b16Styles = 'text-[1rem] leading-[1.5rem] font-normal tracking-[.02em]'

  const b14Styles = 'text-[0.9rem] leading-[1rem] font-normal tracking-[.03em]'

  const b12Styles = 'text-[0.75rem] leading-[1rem] font-normal tracking-[.03em]'

  const l24Styles = 'text-[1.5rem] leading-[1.5rem] font-bold tracking-[.02em]'

  const l20Styles = 'text-[1.25rem] leading-[1.25rem] font-bold tracking-[.02em]'

  const l16Styles = 'text-[1rem] leading-[1rem] font-bold tracking-[.02em]'

  const l14Styles = 'text-[0.9rem] leading-[0.75rem] font-bold tracking-[.03em]'

  const l12Styles = 'text-[0.75rem] leading-[0.75rem] font-bold tracking-[.03em]'

  const getStyles = () => {
    switch (variant) {
      case 'h1':
        return h1Styles
      case 'h2':
        return h2Styles
      case 'h3':
        return h3Styles
      case 'h4':
        return h4Styles
      case 'h5':
        return h5Styles
      case 'h6':
        return h6Styles
      case 'd1':
        return d1Styles
      case 'd2':
        return d2Styles
      case 'd3':
        return d3Styles
      case 'd4':
        return d4Styles
      case 'b24':
        return b24Styles
      case 'b20':
        return b20Styles
      case 'b16':
        return b16Styles
      case 'b14':
        return b14Styles
      case 'b12':
        return b12Styles
      case 'l24':
        return l24Styles
      case 'l20':
        return l20Styles
      case 'l16':
        return l16Styles
      case 'l14':
        return l14Styles
      case 'l12':
        return l12Styles
    }
  }

  const getElement = () => {
    if (element) {
      return element
    }

    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)) {
      return variant
    }

    return 'p'
  }

  const typographyElement = getElement()

  return createElement(
    typographyElement,
    {
      className: clsx(getStyles(), className, typographyElement === 'span' && 'inline-block'),
      ...props,
    },
    children,
  )
}
