import { memo, MouseEvent } from 'react'
import { IconName } from '@library/components/atoms/Icon/utils'
import { getCountClass, getTabItemClasses } from '@library/components/molecules/TabItem/utils'
import { transitionClasses } from '@library/utils'
import clsx from 'clsx'

import { Label } from '../Label'

const tabItemClass = `
  flex
  items-center
  justify-center
  outline-none
  border
  border-borderNeutralDefault
  cursor-pointer
  [&_*]:cursor-pointer
  group
  focus:ring-borderBrandDefault focus:ring-[3px]
`

const tabItemHoverClass = `
  hover:border-borderNeutralDefaultHover
`

const tabItemSelectedClass = `
  !border-backgroundBlueSoft
  bg-backgroundBlueSoft
`

export interface TabItemProps {
  size?: 'small' | 'medium' | 'large'
  index: number
  icon?: IconName
  label?: string
  count?: number
  isSelected: boolean
  handleOnClick?: (event: MouseEvent<HTMLButtonElement>) => void
  handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement>
}

export const TabItem = memo(
  ({ size = 'medium', icon, label, count, isSelected, handleOnClick, handleKeyDown }: TabItemProps) => {
    return (
      <button
        className={clsx(
          isSelected && tabItemSelectedClass,
          tabItemClass,
          tabItemHoverClass,
          getTabItemClasses(size),
          transitionClasses,
        )}
        onKeyDown={handleKeyDown}
        onClick={(event) => {
          if (!isSelected) handleOnClick?.(event)
        }}
        role="tab"
        aria-selected={isSelected}
      >
        <Label
          className={clsx(transitionClasses, 'text-foregroundNeutralPrimary')}
          size={size}
          labelText={label}
          labelIcon={icon}
        />
        {count && <Label size={size} labelText={`${count}`} className={clsx(getCountClass(isSelected))} />}
      </button>
    )
  },
)

TabItem.displayName = 'TabItem'
