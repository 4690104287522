import clsx from 'clsx'

type Props = {
  url: string
  className?: string
  alt?: string
}

export const ImageBlock = ({ url, className = 'w-16 md:w-24 md:h-24 lg:w-32 lg:h-32', alt }: Props) => (
  <img className={clsx('h-auto', className)} src={url} alt={alt} />
)
