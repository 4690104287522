import { clsx } from 'clsx'

const barContainerClasses = `
  absolute top-0 left-0 right-0 h-1 overflow-hidden z-tooltip
`

const barClasses = `
  h-full bg-backgroundBrandDefault transition-all duration-300 ease-in-out rounded-b-xl
`

export type ProgressBarProps = {
  progress: number
  className?: string
  testId?: string
}

export const ProgressBar = ({ progress, className, testId }: ProgressBarProps) => {
  return (
    <div data-testid={testId} className={clsx(barContainerClasses, className)}>
      <div className={clsx(barClasses)} style={{ width: `${progress}%` }} />
    </div>
  )
}
