import { memo } from 'react'
import { Icon } from '@library/components/atoms'
import { Size } from '@library/types'
import { transitionClasses } from '@library/utils'
import clsx from 'clsx'
const baseClasses = `
  flex items-center justify-center cursor-pointer bg-backgroundNeutralSoft hover:bg-backgroundNeutralSoftHover active:bg-backgroundNeutralSoftPressed rounded-full
`

export interface CloseIconButtonProps {
  testId?: string
  className?: string
  size: Size
  handleOnClick?: () => void
}

export const CloseIconButton = memo(({ testId, handleOnClick, size, className }: CloseIconButtonProps) => {
  const getSizeClasses = (size: Size) => {
    switch (size) {
      case 'xSmall':
        return 'h-6 w-6'
      case 'small':
        return 'h-8 w-8'
      case 'medium':
        return 'h-10 w-10'
      case 'large':
        return 'h-12 w-12'
    }
  }
  const getSvgSize = (size: Size) => {
    switch (size) {
      case 'xSmall':
        return 'xSmall'
      case 'small':
        return 'xSmall'
      case 'medium':
        return 'small'
      case 'large':
        return 'medium'
      default:
        return 'small'
    }
  }
  return (
    <span className={clsx(baseClasses, transitionClasses, className, getSizeClasses(size))}>
      <Icon
        testId={testId}
        handleOnClick={handleOnClick}
        iconName={size === 'xSmall' ? 'close' : 'close-big'}
        size={getSvgSize(size)}
      />
    </span>
  )
})

CloseIconButton.displayName = 'CloseIconButton'
