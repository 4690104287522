import { Size } from '@library/types'

export const getTabItemClasses = (size: Size) => {
  switch (size) {
    case 'small':
      return 'h-10 px-4 gap-x-1 rounded-md'
    case 'medium':
      return 'h-12 px-5 gap-x-1.5 rounded-lg'
    case 'large':
      return 'h-14 px-5 gap-x-2 rounded-xl'
  }
}

export const getCountClass = (isSelected: boolean) => {
  switch (isSelected) {
    case true:
      return '[&>span]:text-foregroundNeutralPrimary'
    default:
      return 'group-hover:[&>span]:!text-foregroundNeutralPrimary [&>span]:!text-foregroundNeutralTertiary'
  }
}
