import { useCallback } from 'react'
import mixpanel from 'mixpanel-browser'

import { useGetPractice } from '@shared/data/practice/hooks'
import { Accessor } from '@shared/data/types'
import { useAuth } from '@shared/stores/authStore'
import { usePracticeStore } from '@shared/stores/practiceStore'

export const usePracticeMixpanel = () => {
  const { trackingId } = useAuth.getState()
  const { selectedPracticeId: practiceId } = usePracticeStore()
  const { data: practiceData } = useGetPractice(practiceId, Accessor.PRACTICE_MEMBER)

  const identify = useCallback(
    async (trackingId: string) => {
      if (!practiceData?.data?.isDemo) {
        mixpanel.identify(trackingId)
        mixpanel.people.set({ 'Practice name': practiceData?.data?.name, 'userType': 'practitioner' })
      }
    },
    [practiceData],
  )

  const trackPracticeEvent = useCallback(
    async (name: string, props?: Record<string, unknown>) => {
      if (practiceData && !practiceData?.data?.isDemo) {
        mixpanel.track(name, {
          'Practice name': practiceData?.data.name,
          'userType': 'practitioner',
          trackingId,
          ...props,
        })
      }
    },
    [practiceData, trackingId],
  )

  return {
    identify,
    trackPracticeEvent,
  }
}
