import { memo } from 'react'
import { Size } from '@library/types'
import clsx from 'clsx'

import { IconName, icons } from './utils'

export interface IconProps {
  testId?: string
  iconName: IconName
  size: Size | 'xLarge'
  outlined?: boolean
  /**
   * This controls the colour of the SVG icon, it should be a text colour class e.g. text-foregroundNeutralPrimary
   * */
  color?: React.HTMLAttributes<HTMLElement>['className']
  viewBox?: string
  state?: 'success' | 'error'
  className?: string
  handleOnClick?: () => void
}

export const Icon = memo(
  ({
    testId,
    handleOnClick,
    iconName,
    color = 'text-foregroundNeutralPrimary',
    size,
    viewBox = '0 0 24 24',
    outlined,
    state,
    className,
  }: IconProps) => {
    let svgSize
    const Svg = icons[iconName]
    switch (size) {
      case 'xSmall':
        svgSize = '1rem'
        break
      case 'small':
        svgSize = '1.25rem'
        break
      case 'medium':
        svgSize = '1.5rem'
        break
      case 'large':
        svgSize = '2rem'
        break
      case 'xLarge':
        svgSize = '2.5rem'
        break
    }

    return (
      <span
        onClick={handleOnClick}
        data-testid={testId}
        className={clsx(
          'flex items-center',
          color,
          outlined && 'p-2.5 border rounded-full borderNeutralSoft',
          state === 'error' && '!border-backgroundErrorSoft !bg-backgroundErrorSoft',
          state === 'success' && '!border-backgroundSuccessSoft !bg-backgroundSuccessSoft',
          className,
        )}
      >
        <Svg className="inline-flex [&>path]:fill-current" width={svgSize} height={svgSize} viewBox={viewBox} />
      </span>
    )
  },
)

Icon.displayName = 'Icon'
