import { HTMLAttributes } from 'react'
import clsx from 'clsx'

const smallStyles = `font-normal text-[0.875rem] leading-[1rem] tracking-[0.0175rem] text-neutral-primary`

const mediumStyles = `font-medium text-[1rem] leading-[1.5rem] tracking-[0.02rem] text-neutral-primary`

const largeStyles = `text-xl font-normal  leading-6  tracking-[0.4px]  text-[#2D3643]`

type LabelSize = 'small' | 'medium' | 'large'

interface ParagraphProps extends HTMLAttributes<HTMLLabelElement> {
  size: LabelSize
}

export const Label = ({ className, children, size, ...props }: ParagraphProps) => {
  let sizeStyles = ''
  switch (size) {
    case 'small':
      sizeStyles = smallStyles
      break
    case 'medium':
      sizeStyles = mediumStyles
      break
    case 'large':
      sizeStyles = largeStyles
      break
  }
  return (
    <label className={clsx(className, sizeStyles)} {...props}>
      {children}
    </label>
  )
}
