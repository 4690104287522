import mixpanel, { Config } from 'mixpanel-browser'

import { getPatientEventProps } from '@shared/stores/utils'

type EventProps = Record<string, unknown>

export const initMixpanel = (config?: Partial<Config>) => {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, config)
}

initMixpanel()

const actions = {
  /**
   *
   * @param name name of the event
   * @param props additional properties to be stored again an event e.g. {'Practice name': 'Gums n Roses'}
   */
  track: (name: string, props?: EventProps) => {
    const patientEventProps = getPatientEventProps()

    if (name === 'Opted out of analytics') {
      mixpanel.track(name)
    } else if (!patientEventProps.demo) {
      /**
       * Cookiebot allows the user to toggle consent for statistics, if they do so and click the 'Allow selection' button
       * or if they click the 'Allow all' button we can track their data through Mixpanel
       * we can confirm this anywhere within the app by checking 'window.Cookiebot?.consent.statistics'
       */
      if (window.Cookiebot?.consent?.statistics) {
        mixpanel.track(name, { ...props, ...patientEventProps })
      }
    }
  },
  identify: (trackingId: string, practiceName: string) => {
    if (window.Cookiebot?.consent?.statistics) {
      mixpanel.identify(trackingId)
      mixpanel.people.set({ 'Practice name': practiceName, 'userType': 'patient' })
    }
  },
  reset: () => {
    if (window.Cookiebot?.consent?.statistics) mixpanel.reset()
  },
}

export const PatientMixpanel = actions
