import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { t } from 'i18next'

import { Banner } from '@shared/components/Banner'
import { usePracticeMixpanel } from '@shared/hooks/usePracticeMixpanel/usePracticeMixpanel'
import { useAuth } from '@shared/stores/authStore'

export const RoutesCookieWrapper = () => {
  const { trackPracticeEvent } = usePracticeMixpanel()
  const { trackingId } = useAuth()
  useEffect(() => {
    const eventName = `Page Visit: ${window.location.pathname}${window.location.search}`
    /**
     * When a user directly enters a URL this event is fired before the tracking ID for a patient or practitioner is available,
     * this guard along with the one in the Mixpanel module ensures that any of the events for pages with auth are only sent
     * once the tracking ID is available
     */
    if (eventName.includes('/practice/dashboard') && trackingId) {
      trackPracticeEvent(eventName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])

  const [isBannerVisisble, setIsBannerVisisble] = useState<boolean>(!localStorage.getItem('practice-cookie-consent'))

  const handleOnClick = () => {
    localStorage.setItem('practice-cookie-consent', 'consented')
    setIsBannerVisisble(false)
  }

  return (
    <>
      <Outlet />
      {isBannerVisisble && (
        <Banner
          testid="practice-cookie-consent-banner"
          className="fixed bottom-0 left-0 w-full rounded-none"
          buttonText="OK"
          text={
            <p>
              {t('practiceCookieConsentBanner.text')}&nbsp;
              <a className="underline" href="https://www.practihealth.co.uk/cookies" target="_blank" rel="noreferrer">
                {t('practice.accountPage.cookiePolicy')}
              </a>
              .
            </p>
          }
          onClick={handleOnClick}
        />
      )}
    </>
  )
}
