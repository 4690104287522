import { memo, useEffect, useRef, useState } from 'react'
import { Button } from '@library/components/molecules'
import { TooltipModal } from '@library/components/molecules/TooltipModal'
import clsx from 'clsx'

import { Portal } from '@shared/components/Portal/Portal'
import { useOnClickOutside } from '@shared/hooks/useOnClickOutside/useOnClickOutside'
import { ProductTourStepProps } from '@shared/stores/productTourStore'

import { getModalPosition } from './utils'

export interface ProductTourProps {
  targetElement?: HTMLDivElement
  step: ProductTourStepProps
  onNext?: () => void
  onClose?: () => void
  currentStep?: number
  totalSteps?: number
}
interface Coords {
  top: number
  left: number
}
export const ProductTour = memo(
  ({ targetElement, step, onNext, onClose, currentStep, totalSteps }: ProductTourProps) => {
    const [modalPosition, setModalPosition] = useState<Coords>({ top: 0, left: 0 })
    const contentRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const updatePosition = () => {
        if (targetElement && contentRef.current) {
          const containerHeight = contentRef.current?.getBoundingClientRect().height || 0
          const containerWidth = contentRef.current?.getBoundingClientRect().width || 0
          const rect = targetElement.getBoundingClientRect()
          const tooltipPosition = getModalPosition(
            step.position!,
            step.alignment!,
            step.margin!,
            containerHeight,
            containerWidth,
            rect,
          )
          setModalPosition(tooltipPosition)
        }
      }
      updatePosition()
      window.addEventListener('resize', updatePosition)
      window.addEventListener('scroll', updatePosition)
      targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })

      return () => {
        window.removeEventListener('resize', updatePosition)
        window.removeEventListener('scroll', updatePosition)
      }
    }, [targetElement, step.position, step.alignment, step.margin])

    const progress = ((currentStep! + 1) / totalSteps!) * 100

    const buttons = (
      <>
        {step.altButton && (
          <Button
            testId="product-tour-alt"
            size="small"
            appearance="subtle"
            mode="accent"
            text={step.altButton}
            onClick={step.altFunction}
          />
        )}
        {step.nextButton && (
          <Button
            testId="product-tour-next"
            size="small"
            appearance="primary"
            mode="accent"
            text={step.nextButton}
            onClick={onNext}
          />
        )}
      </>
    )

    useOnClickOutside(contentRef, () => {
      if (currentStep === totalSteps! - 1) onClose!()
    })

    return (
      <span className="relative flex">
        <Portal>
          <div
            data-testid={`product-tour-tooltip-${currentStep}`}
            ref={contentRef}
            className={clsx(targetElement ? 'absolute my-6' : 'fixed bottom-5 left-5', 'drop-shadow-notificationLarge')}
            style={{
              top: targetElement ? modalPosition?.top : undefined,
              left: targetElement ? modalPosition?.left : undefined,
            }}
          >
            <TooltipModal
              arrow={Boolean(targetElement)}
              alignment={step.alignment}
              onClose={onClose}
              buttons={buttons}
              closeIcon
              progress={progress}
              isProductTour
              position={step.position}
              title={step.title}
              supportText={step.supportText}
            />
          </div>
        </Portal>
      </span>
    )
  },
)

ProductTour.displayName = 'ProductTour'
