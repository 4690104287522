import { Paragraph } from '@library/components/atoms'
import { t } from 'i18next'

import { Grid } from '@shared/components/Grid'
import { Label } from '@shared/components/Label'
import { LogoWatermark } from '@shared/components/Logo'

export const FinancePromoFooter = () => {
  return (
    <footer data-testid="footer" className="w-full text-neutral-primary pt-12">
      <Grid className="!max-w-[75rem] mx-auto">
        <div className="flex flex-col lg:grid grid-cols-12 col-span-12 gap-4 place-items-start">
          <div className="flex flex-col w-full justify-start items-start gap-1 col-span-12 md:col-span-3 px-6 md:px-[3.75rem]">
            <Label size="small" className="text-neutral-secondary">
              powered by:
            </Label>
            <LogoWatermark colour="bluebell" width={145} className="-ml-2" />
          </div>
          <div className="flex flex-col w-full justify-start gap-y-10 md:gap-8 items-start col-span-12 md:col-span-9 px-6 md:px-[3.75rem]">
            <div className="flex flex-col w-full col-span-8 gap-4">
              <Paragraph size="small" className="text-neutral-secondary">
                {t('patient.financePromoPage.footer.regulatoryText.0')}
              </Paragraph>
              <Paragraph size="small" className="text-neutral-secondary">
                {t('patient.financePromoPage.footer.regulatoryText.1')}
              </Paragraph>
              <Paragraph size="small" className="text-neutral-secondary">
                {t('patient.financePromoPage.footer.regulatoryText.2')}
              </Paragraph>
              <Paragraph size="small" className="text-neutral-secondary">
                {t('patient.financePromoPage.footer.regulatoryText.3')}
              </Paragraph>
            </div>
          </div>
        </div>
      </Grid>
    </footer>
  )
}
