import { ImgHTMLAttributes, useState } from 'react'

export interface PracticeLogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  imagePath: string
}

export const PracticeLogo = ({ imagePath, ...props }: PracticeLogoProps) => {
  const [imageError, setImageError] = useState(false)

  const src = imageError
    ? '/images/patient/logo.svg'
    : `${import.meta.env.DEV ? 'https://app.dev.boostbrush.co.uk' : window.location.origin}/${imagePath}`

  return <img alt="practice logo" src={src} onError={() => setImageError(true)} {...props} />
}
