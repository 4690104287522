import { IconName } from '@library/components/atoms/Icon/utils'
import { Size, State } from '@library/types'

export const getNotificationClasses = (size: Size) => {
  switch (size) {
    case 'small':
      return 'rounded-md py-1.5 px-2 gap-2 drop-shadow-notificationSmall'
    case 'medium':
      return 'rounded-lg py-2.5 px-3 gap-2 drop-shadow-notificationMedium'
    case 'large':
      return 'rounded-[0.625rem] p-3 gap-3 drop-shadow-notificationLarge'
  }
}

export const getNotificationTextSize = (size?: Size) => {
  switch (size) {
    case 'small':
      return 'small'
    case 'medium':
      return 'small'
    case 'large':
      return 'medium'
    default:
      return 'small'
  }
}

export const getNotificationIcon = (state?: State) => {
  switch (state) {
    case 'success':
      return 'check' as IconName
    case 'error':
      return 'info' as IconName
    default:
      return 'check' as IconName
  }
}

export const getNotificationIconColor = (state: State) => {
  switch (state) {
    case 'success':
      return 'text-backgroundSuccessDefault'
    case 'error':
      return 'text-backgroundErrorDefault'
    default:
      return 'text-foregroundNeutralPrimary'
  }
}
