export const getTooltipPosition = (
  position: 'top' | 'bottom' | 'left' | 'right',
  containerHeight: number,
  containerWidth: number,
  rect: {
    top?: number
    left?: number
    bottom?: number
    right?: number
  },
) => {
  switch (position) {
    case 'top':
      return {
        top: window.scrollY + (rect.top! - containerHeight - 10),
        left: rect.left! - containerWidth / 2 + 12,
      }
    case 'bottom':
      return {
        top: window.scrollY + (rect.top! + 30),
        left: rect.left! - containerWidth / 2 + 12,
      }
    case 'right':
      return {
        top: window.scrollY + (rect.top! - 45),
        left: rect.left! + 30,
      }
    case 'left':
      return {
        top: window.scrollY + (rect.top! - 45),
        left: rect.left! - containerWidth - 10,
      }
    default:
      return {
        top: window.scrollY + (rect.top! - containerHeight - 10),
        left: rect.left! - containerHeight / 2,
      }
  }
}
