import { clsx } from 'clsx'

type Props = {
  items: string[]
  direction?: 'Left' | 'Right'
}

export const Marquee = ({ items, direction }: Props) => {
  return (
    <div className="w-full relative flex overflow-x-hidden">
      <div
        className={clsx(
          direction === 'Right' ? 'animate-marqueeRight' : 'animate-marquee',
          `inline-flex whitespace-nowrap`,
        )}
      >
        {items.map((item) => (
          <span key={item} className="mx-4">
            <img src={item} alt="logo" className="min-h-[92px] min-w-[92px]" />
          </span>
        ))}
      </div>
      <div
        className={clsx(
          direction === 'Right' ? 'animate-marqueeRight2' : 'animate-marquee2',
          `absolute top-0 inline-flex whitespace-nowrap`,
        )}
      >
        {items.map((item) => (
          <span key={item} className="mx-4">
            <img src={item} alt="logo" className="min-h-[92px] min-w-[92px]" />
          </span>
        ))}
      </div>
    </div>
  )
}
