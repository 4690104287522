import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Button } from '@library/components/molecules/Button'
import clsx from 'clsx'

const INITIAL_EXPANSION = '...'
const LEFT_EXPANSION = '... '
const RIGHT_EXPANSION = ' ...'

const baseClasses = `
  w-full
  flex
  items-center
  justify-between
`

export interface PaginationProps {
  size: 'small' | 'xSmall'
  /** Assuming the records come from a parent component. Will affect number of pages. */
  totalRecords: number
  /** Affects whether 'Previous' and 'Next' are shown on navigation. If false, only chevrons are shown. */
  isCompact?: boolean
  /** Affects whether the page number buttons are shown. */
  pageNumbers?: boolean
  /** Used to determine the number of pages */
  recordsPerPage?: number
  /** Function called when page number is set */
  onSetCurrentPage?: (page: number) => void
  className?: string
}

export const Pagination = memo(
  forwardRef(function Pagination(
    {
      size = 'small',
      totalRecords,
      pageNumbers = true,
      isCompact = false,
      onSetCurrentPage,
      recordsPerPage = 16,
      className,
    }: PaginationProps,
    ref, // Accept the ref parameter here
  ) {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [currentButtons, setCurrentButtons] = useState<(number | string)[]>([])

    useImperativeHandle(ref, () => ({
      setCurrentPage,
    }))

    const totalPages = useMemo(() => {
      return Math.ceil(totalRecords / recordsPerPage)
    }, [totalRecords, recordsPerPage])

    const buttons = useMemo(() => {
      const array = []
      for (let i = 1; i <= totalPages; i++) {
        array.push(i)
      }
      return array
    }, [totalPages])

    const handleSetCurrentPage = (pageNumber: number) => {
      setCurrentPage(pageNumber)
      onSetCurrentPage?.(pageNumber)
    }

    const handleNextPage = () => {
      handleSetCurrentPage(currentPage + 1)
    }

    const handlePrevPage = () => {
      handleSetCurrentPage(currentPage - 1)
    }

    const handleButtonLogic = () => {
      let tempButtons: Array<number | string> = [...currentButtons]

      if (buttons.length < 6) {
        tempButtons = buttons
      } else if (currentPage >= 1 && currentPage < 5) {
        const sliced = buttons.slice(0, 5)
        tempButtons = [...sliced, INITIAL_EXPANSION, buttons.length]
        // [1, 2, 3, 4, 5, '...', 10]
      } else if (currentPage > 4 && currentPage < buttons.length - 4) {
        const sliced1 = buttons.slice(currentPage - 2, currentPage)
        const sliced2 = buttons.slice(currentPage, currentPage + 1)
        tempButtons = [1, LEFT_EXPANSION, ...sliced1, ...sliced2, RIGHT_EXPANSION, buttons.length]
        // [1, '...', 4, 5, 6, '...', 10]
      } else if (currentPage >= buttons.length - 4) {
        const sliced = buttons.slice(buttons.length - 5)
        tempButtons = [1, LEFT_EXPANSION, ...sliced]
        // [1, '...', 6, 7, 8, 9, 10]
      }
      setCurrentButtons(tempButtons)
    }

    const handlePageNumberClick = (button: number | string) => {
      if (typeof button === 'string') {
        if (button === RIGHT_EXPANSION || button === INITIAL_EXPANSION) {
          handleSetCurrentPage(currentPage + 3)
        }
        if (button === LEFT_EXPANSION) {
          handleSetCurrentPage(currentPage - 3)
        }
      } else {
        handleSetCurrentPage(button)
      }
    }

    useEffect(() => {
      handleButtonLogic()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalPages])

    const nextDisabled = currentPage === totalPages
    const prevDisabled = currentPage === 1

    return totalPages > 1 ? (
      <div className={clsx(baseClasses, className)}>
        <Button
          className={clsx(prevDisabled ? 'cursor-not-allowed ' : 'cursor-pointer')}
          appearance="ghost"
          text={!isCompact ? 'Previous' : ''}
          mode="accent"
          disabled={prevDisabled}
          size={size}
          role="button"
          leftIcon="chevron-left"
          onClick={handlePrevPage}
        />
        {pageNumbers && (
          <div className="flex items-center justify-center gap-x-1">
            {currentButtons.map((button, index) => (
              <Button
                key={index}
                size={size}
                mode="accent"
                appearance="ghost"
                text={button.toString()}
                onClick={() => handlePageNumberClick(button)}
                className={clsx(
                  currentPage === button
                    ? '!bg-backgroundBlueSoft !border !border-backgroundBlueSoft'
                    : `!border !border-borderNeutralDefault`,
                  `hover:!border-borderNeutralDefaultHover`,
                )}
              />
            ))}
          </div>
        )}
        <Button
          className={clsx(nextDisabled ? 'cursor-not-allowed' : 'cursor-pointer')}
          appearance="ghost"
          text={!isCompact ? 'Next' : ''}
          mode="accent"
          disabled={nextDisabled}
          size={size}
          role="button"
          rightIcon="chevron-right"
          onClick={handleNextPage}
        />
      </div>
    ) : null
  }),
)
