export const getAvatarSize = (size: number) => {
  switch (size) {
    case 16:
      return 'h-4 w-4'
    case 20:
      return 'h-5 w-5'
    case 24:
      return 'h-6 w-6'
    case 32:
      return 'h-8 w-8'
    case 40:
      return 'h-10 w-10'
    case 48:
      return 'h-12 w-12'
    case 56:
      return 'h-14 w-14'
  }
}

export const getAvatarTextSize = (size?: number) => {
  switch (size) {
    case 16:
      return 'small'
    case 20:
      return 'small'
    case 24:
      return 'small'
    case 32:
      return 'small'
    case 40:
      return 'large'
    case 48:
      return 'xLarge'
    case 56:
      return 'xLarge'
    default:
      return 'small'
  }
}

export type AvatarColorNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18
export const colorClasses = {
  1: 'bg-avatars01',
  2: 'bg-avatars02',
  3: 'bg-avatars03',
  4: 'bg-avatars04',
  5: 'bg-avatars05',
  6: 'bg-avatars06',
  7: 'bg-avatars07',
  8: 'bg-avatars08',
  9: 'bg-avatars09',
  10: 'bg-avatars10',
  11: 'bg-avatars11',
  12: 'bg-avatars12',
  13: 'bg-avatars13',
  14: 'bg-avatars14',
  15: 'bg-avatars15',
  16: 'bg-avatars16',
  17: 'bg-avatars17',
  18: 'bg-avatars18',
}

export const getAvatarColor = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  let colorNumber = Math.abs(hash) % 18
  if (colorNumber === 0) colorNumber = 18

  return colorClasses[colorNumber as AvatarColorNumber]
}
