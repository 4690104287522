import { Size } from '@library/types'

export const getTextSize = (size: Size) => {
  switch (size) {
    case 'xSmall':
      return 'text-[0.75rem] leading-5 tracking-[0.015rem]'
    case 'small':
      return 'text-[0.875rem] leading-5'
    case 'medium':
      return 'text-[1rem] leading-6'
    case 'large':
      return 'text-[1.125rem] leading-7'
    case 'xLarge':
      return 'text-[1.25rem] leading-8'
  }
}

export const transitionClasses = ' transition-colors duration-200 ease-out'

export const convertToCurrency = (pence: number, currency: string) => {
  return `${currency}${(pence / 100).toFixed(2)}`
}
