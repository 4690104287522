import { ButtonProps } from './Button'

export const getDisabledClasses = (appearance: ButtonProps['appearance']) => {
  switch (appearance) {
    case 'primary':
      return 'bg-foregroundNeutralDisabled text-white !cursor-not-allowed'
    case 'subtle':
      return 'bg-backgroundNeutralSubtleDisabled text-foregroundNeutralDisabled !cursor-not-allowed'
    case 'outline':
    case 'ghost':
      return '!bg-transparent !text-foregroundNeutralDisabled !cursor-not-allowed'
  }
}

export const getSizeClasses = (size: ButtonProps['size']) => {
  switch (size) {
    case 'xSmall':
      return 'h-8 p-3 rounded-sm text-[0.875rem]'
    case 'small':
      return 'h-10 p-4 rounded-md text-[0.875rem]'
    case 'medium':
      return 'h-12 p-5 rounded-lg text-[1rem]'
    case 'large':
      return 'h-14 p-6 rounded-xl text-[1rem]'
  }
}

export const getTextClasses = (mode: ButtonProps['mode'], appearance: ButtonProps['appearance']) => {
  let text = ''
  if (appearance === 'primary') {
    text = 'text-white'
  } else if (appearance === 'ghost') {
    text = 'text-foregroundNeutralPrimary'
  } else if (appearance === 'outline') {
    text = 'text-foregroundNeutralTertiary'
  } else if (appearance === 'subtle') {
    switch (mode) {
      case 'accent':
        text = 'text-foregroundBrandPrimary'
        break
      case 'danger':
        text = 'text-foregroundErrorPrimary'
        break
      case 'positive':
        text = 'text-foregroundSuccessPrimary'
        break
      case 'warning':
        text = 'text-foregroundWarningPrimary'
    }
  }
  return text
}

export const getBackgroundClasses = (mode: ButtonProps['mode'], appearance: ButtonProps['appearance']) => {
  let background = ''
  switch (mode) {
    case 'accent':
      if (appearance === 'primary') {
        background =
          'bg-backgroundBrandDefault hover:bg-backgroundBrandDefaultHover active:bg-backgroundBrandDefaultPressed'
      } else if (appearance === 'subtle') {
        background = 'bg-backgroundBlueSoft hover:bg-backgroundBlueSoftHover active:bg-backgroundBlueSoftPressed'
      } else if (appearance === 'ghost' || appearance === 'outline') {
        background = 'bg-transparent hover:bg-foregroundLightPrimaryHover active:bg-foregroundLightPrimaryPressed'
      }
      break
    case 'danger':
      if (appearance === 'primary') {
        background =
          'bg-backgroundErrorDefault hover:bg-backgroundErrorDefaultHover active:bg-backgroundErrorDefaultPressed'
      } else if (appearance === 'subtle') {
        background = 'bg-backgroundErrorSoft hover:bg-backgroundErrorSoftHover active:bg-backgroundErrorSoftPressed'
      }
      break
    case 'warning':
      if (appearance === 'primary') {
        background =
          'bg-backgroundWarningDefault hover:bg-backgroundWarningDefaultHover active:bg-backgroundWarningDefaultPressed'
      } else if (appearance === 'subtle') {
        background =
          'bg-backgroundWarningSoft hover:bg-backgroundWarningSoftHover active:bg-backgroundWarningSoftPressed'
      }
      break
    case 'positive':
      if (appearance === 'primary') {
        background =
          'bg-backgroundSuccessDefault hover:bg-backgroundSuccessDefaultHover active:bg-backgroundSuccessDefaultPressed'
      } else if (appearance === 'subtle') {
        background =
          'bg-backgroundSuccessSoft hover:bg-backgroundSuccessSoftHover active:bg-backgroundSuccessSoftPressed'
      }
  }
  return background
}
