import { ReactNode } from 'react'
import { clsx } from 'clsx'

import { Button } from '@shared/components/Button'

import { Typography } from '../Typography'

type Props = {
  onClick?: () => void
  buttonText?: string
  text?: ReactNode
  className?: string
  testid?: string
}

export const Banner = ({ buttonText, className, onClick, text, testid }: Props) => {
  return (
    <section
      data-testid={testid}
      className={clsx('bg-accent1-900 py-3 px-6 rounded-2xl flex justify-between items-center', className)}
    >
      <Typography element="div" variant="l16" className="text-primary-contrast">
        {text}
      </Typography>
      <Button variant="primary" text={buttonText} onClick={onClick} className="mr-28" />
    </section>
  )
}
