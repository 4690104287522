import { usePatientPracticeStore } from './patientPracticeStore'

/** Returns and object containing the user type and their tracking ID */
export const getPatientEventProps = () => {
  const { trackingId: patientTrackingId, practices } = usePatientPracticeStore.getState()
  const demo = practices[0]?.demo
  const practiceName = practices[0]?.name
  let userType = undefined
  if (patientTrackingId) userType = 'patient'
  const trackingId = patientTrackingId || undefined
  return { userType, trackingId, demo, 'Practice name': practiceName }
}
