import { PropsWithChildren, RefObject, SetStateAction } from 'react'
import { Icon } from '@library/components/atoms/Icon'
import { IconName } from '@library/components/atoms/Icon/utils'

export type TooltipButtonProps = {
  buttonRef: RefObject<HTMLButtonElement>
  setIsHovered: React.Dispatch<SetStateAction<boolean>>
  icon?: IconName
  color?: string
}

export const TooltipButton = ({
  buttonRef,
  setIsHovered,
  icon,
  color,
  children,
}: PropsWithChildren<TooltipButtonProps>) => {
  return (
    <span
      role="tooltip"
      ref={buttonRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {icon && <Icon iconName={icon} size="small" color={color || 'currentColor'} />}
    </span>
  )
}

TooltipButton.displayName = 'TooltipButton'
