import { QueryFunctionContext } from '@tanstack/react-query'

import {
  Accessor,
  GetPracticeInstalments,
  GetPracticeMembers,
  GetPractitioners,
  GetTreatmentDefinition,
  GetTreatmentDefinitions,
  ITreatmentGuide,
  PostAttachments,
  Practice,
  PracticePlans,
  PracticePublic,
  User,
} from '@shared/data/types'
import { patientFetch, practiceFetch as fetch, unprotectedFetch } from '@shared/utils/fetch'
import { newApiBaseUrl, newApiUrls } from '@shared/utils/urls'

interface QueryArgs {
  queryKey: string[]
}

export interface QueryKey {
  queryKey: string[]
}

export const accessFetch = (accessor: Accessor) => (accessor === Accessor.PRACTICE_MEMBER ? fetch : patientFetch)

const fetchers = {
  // fetchers for new backend API
  GET_AUTH_USER: (_: QueryKey, accessor: Accessor): Promise<User> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.auth}${newApiUrls.user}`,
    })
  },
  GET_PRACTICE_MEMBERS: ({ queryKey }: QueryArgs): Promise<GetPracticeMembers> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${queryKey[1]}${newApiUrls.members}`,
    }),
  GET_PRACTICE: (_context: QueryKey, practiceId: string, accessor: Accessor): Promise<Practice> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${practiceId}`,
    })
  },
  GET_PRACTITIONERS: ({ queryKey }: QueryArgs): Promise<GetPractitioners> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practitioners}`,
      params: {
        practiceId: queryKey[1],
      },
    }),
  GET_TREATMENT_GUIDES: (_: QueryKey, treatmentGuideId: string, accessor: Accessor): Promise<ITreatmentGuide> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.treatmentGuides}/${treatmentGuideId}`,
    })
  },
  GET_TREATMENT_DEFINITION: (
    _queryContext: QueryKey,
    treatmentDefinitionId: string,
    accessor: Accessor,
  ): Promise<GetTreatmentDefinition> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: treatmentDefinitionId
        ? `${newApiUrls.treatmentDefinitions}/${treatmentDefinitionId}`
        : `${newApiUrls.treatmentDefinitions}`,
    })
  },
  GET_TREATMENT_DEFINITIONS: (): Promise<GetTreatmentDefinitions> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.treatmentDefinitions}`,
    }),
  GET_S3_OBJECT: ({ queryKey }: QueryArgs): Promise<PostAttachments> =>
    fetch({
      baseURL: newApiBaseUrl,
      url: `${newApiUrls.auth}${newApiUrls.s3}/put-object`,
      method: 'POST',
      data: { contentType: queryKey[1] },
    }),
  GET_PRACTICE_PUBLIC: ({ queryKey }: { queryKey: string[] }): Promise<PracticePublic> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${queryKey[1]}/public`,
    }),
  GET_PRACTICE_INSTALMENTS: (
    _query: QueryFunctionContext<string[]>,
    amount: string,
    practiceId: string,
  ): Promise<GetPracticeInstalments> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.dividebuy}${newApiUrls.availableInstalments}`,
      method: 'GET',
      params: {
        amount,
      },
    }).then((res) => {
      return { data: Object.values(res.data) }
    }),
  GET_PRACTICE_PLANS: ({ queryKey }: { queryKey: string[] }): Promise<PracticePlans[]> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: newApiUrls.practicePlans(queryKey[1]),
      data: { contentType: queryKey[1] },
    }),
  GET_STRIPE_PLANS_KEY: ({
    queryKey,
  }: {
    queryKey: string[]
  }): Promise<{ data: { session: { client_secret: string }; accountId: string } }> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: newApiUrls.plansStripeKey,
      data: {
        planId: `${queryKey[2]}`,
      },
    }),
}

export const queries = {
  // queries for new backend API
  GET_AUTH_USER: {
    queryKey: ['GET_AUTH_USER'],
    queryFn: fetchers.GET_AUTH_USER,
  },
  GET_PRACTICE_MEMBERS: {
    queryKey: ['GET_PRACTICE_MEMBERS'],
    queryFn: fetchers.GET_PRACTICE_MEMBERS,
  },
  GET_PRACTICE: {
    queryKey: ['GET_PRACTICE'],
    queryFn: fetchers.GET_PRACTICE,
  },
  GET_PRACTITIONERS: {
    queryKey: ['GET_PRACTITIONERS'],
    queryFn: fetchers.GET_PRACTITIONERS,
  },
  GET_TREATMENT_GUIDES: {
    queryKey: ['GET_TREATMENT_GUIDES'],
    queryFn: fetchers.GET_TREATMENT_GUIDES,
  },
  GET_TREATMENT_DEFINITION: {
    queryKey: ['GET_TREATMENT_DEFINITION'],
    queryFn: fetchers.GET_TREATMENT_DEFINITION,
  },
  GET_TREATMENT_DEFINITIONS: {
    queryKey: ['GET_TREATMENT_DEFINITIONS'],
    queryFn: fetchers.GET_TREATMENT_DEFINITIONS,
  },
  GET_S3_OBJECT: {
    queryKey: ['GET_S3_OBJECT'],
    queryFn: fetchers.GET_S3_OBJECT,
  },
  GET_PRACTICE_PUBLIC: {
    queryKey: ['GET_PRACTICE_PUBLIC'],
    queryFn: fetchers.GET_PRACTICE_PUBLIC,
  },
  GET_PRACTICE_INSTALMENTS: {
    queryKey: ['GET_PRACTICE_INSTALMENTS'],
    queryFn: fetchers.GET_PRACTICE_INSTALMENTS,
  },
  GET_PRACTICE_PLANS: {
    queryKey: ['GET_PRACTICE_PLANS'],
    queryFn: fetchers.GET_PRACTICE_PLANS,
  },
  GET_STRIPE_PLANS_KEY: {
    queryKey: ['GET_STRIPE_PLANS_KEY'],
    queryFn: fetchers.GET_STRIPE_PLANS_KEY,
  },
}
