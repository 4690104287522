import { ReactNode } from 'react'
import { CloseIconButton } from '@library/components/atoms/CloseIconButton'
import { Paragraph } from '@library/components/atoms/Paragraph/Paragraph'
import clsx from 'clsx'

import { Label } from '../Label'
import { ProgressBar } from '../ProgressBar'

import { getArrowClasses } from './utils'

const modalClasses = `
  relative
  flex
  items-center
  justify-center
  max-w-[16.25rem]
  border
  border-borderNeutralDefault
  bg-white
  rounded-lg
  z-tooltip
`

const arrowBaseClasses = `
  absolute
  bg-white
  border
  border-borderNeutralDefault
  h-3 w-3 rotate-45
`

export type TooltipModalProps = {
  title?: string
  supportText?: string
  position: 'top' | 'bottom' | 'left' | 'right'
  isProductTour?: boolean
  progress?: number
  closeIcon?: boolean
  buttons?: ReactNode
  onClose?: () => void
  arrow?: boolean
  alignment?: 'top' | 'left' | 'right' | 'center'
}

export const TooltipModal = ({
  title,
  supportText,
  position = 'top',
  alignment,
  isProductTour,
  progress,
  buttons,
  closeIcon,
  onClose,
  arrow,
}: TooltipModalProps) => {
  return (
    <>
      <div className={clsx(modalClasses, isProductTour && '!max-w-[21.25rem] rounded-2xl')}>
        <div
          className={clsx(
            supportText ? 'p-3' : 'px-2 py-1.5',
            isProductTour && '!p-6',
            'relative flex flex-col gap-y-1 whitespace-no-wrap w-full',
          )}
        >
          {closeIcon && (
            <CloseIconButton
              handleOnClick={onClose}
              testId="close-icon-tooltip"
              size="small"
              className="absolute right-3 top-3"
            />
          )}
          {progress && (
            <ProgressBar
              testId="progress-bar-tooltip"
              progress={progress}
              className={clsx(supportText ? 'px-3' : 'px-2', isProductTour && '!px-6')}
            />
          )}
          {title && (
            <Label
              size={isProductTour ? 'medium' : 'small'}
              labelStrong={isProductTour ? true : false}
              labelText={title}
            />
          )}
          {supportText && (
            <Paragraph
              size={isProductTour ? 'medium' : 'caption'}
              className={clsx(isProductTour ? 'text-foregroundNeutralPrimary' : '!text-foregroundNeutralSecondary')}
            >
              {supportText}
            </Paragraph>
          )}
          {buttons && <div className="mt-4 flex items-center justify-end gap-x-2">{buttons}</div>}
        </div>
        {arrow && <div role="tooltip-arrow" className={clsx(getArrowClasses(position, alignment), arrowBaseClasses)} />}
      </div>
    </>
  )
}

TooltipModal.displayName = 'TooltipModal'
