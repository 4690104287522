import { memo } from 'react'
import {
  AvatarColorNumber,
  colorClasses,
  getAvatarColor,
  getAvatarSize,
  getAvatarTextSize,
} from '@library/components/atoms/Avatar/utils'
import clsx from 'clsx'

import { Paragraph } from '../Paragraph/Paragraph'

const baseClasses = `
  rounded-full
  flex
  items-center
  justify-center
`

export type AvatarSizes = 16 | 20 | 24 | 32 | 40 | 48 | 56

export interface AvatarProps {
  text: string
  size: AvatarSizes
  color?: AvatarColorNumber
}

export const Avatar = memo(({ text, size, color }: AvatarProps) => {
  return (
    <Paragraph
      className={clsx(baseClasses, getAvatarSize(size), color ? colorClasses[color] : getAvatarColor(text))}
      size={getAvatarTextSize(size)}
    >
      {Array.from(text)[0].toUpperCase()}
    </Paragraph>
  )
})

Avatar.displayName = 'Avatar'
