import { HTMLAttributes } from 'react'
import clsx from 'clsx'

const h1Styles = `font-medium text-[2.75rem] lg:text-[4rem] leading-[3.25rem] lg:leading-[4.25rem] tracking-[0.0275rem] lg:tracking-[-0.04rem] text-neutral-primary`
const h2Styles = `font-medium text-[2.75rem] leading-[3.25rem] tracking-[-0.0275rem] text-neutral-primary`
const h3Styles = `font-medium text-[2rem] md:text-[2.25rem] leading-[2.25rem] md:leading-[2.75rem] text-neutral-primary`

type HeadingVariant = 'h1' | 'h2' | 'h3'

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  variant: HeadingVariant
}

export const Heading = ({ className, children, variant, ...props }: HeadingProps) => {
  let variantStyles = ''
  switch (variant) {
    case 'h1':
      variantStyles = h1Styles
      break
    case 'h2':
      variantStyles = h2Styles
      break
    case 'h3':
      variantStyles = h3Styles
      break
  }
  const Heading = variant
  return (
    <Heading className={clsx(className, variantStyles)} {...props}>
      {children}
    </Heading>
  )
}
