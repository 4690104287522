import { InputHTMLAttributes, PropsWithChildren } from 'react'
import clsx from 'clsx'

const inputClasses = `
  bg-white
  border
  border-[#E9EFF6]
  text-gray-900
  text-[1.25rem]
  rounded-lg
  focus:ring-blue-500
  focus:border-blue-500
  inline-block
  w-full
  h-[3.5rem]
  p-[0.8125rem_0.625rem]
`

const numberInputClasses = `pl-10`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const InputContainer = ({
  children,
  type,
}: PropsWithChildren<{ type: InputHTMLAttributes<HTMLInputElement>['type'] }>) => {
  if (type === 'number') {
    return (
      <div className="relative">
        <div className="absolute left-4 top-[18px] leading-none text-xl">£</div>
        <span>{children}</span>
      </div>
    )
  }
  return children
}

export const Input = ({ className, ...props }: InputProps) => {
  return (
    <InputContainer type={props.type}>
      <input className={clsx(inputClasses, props.type === 'number' ? numberInputClasses : '', className)} {...props} />
    </InputContainer>
  )
}
