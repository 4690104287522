import { ReactNode } from 'react'
import clsx from 'clsx'

import { DebugColumn } from './DebugColumn'

type GridProps = {
  className?: string
  children: ReactNode
  debug?: boolean
}

export const Grid = ({ children, className, debug }: GridProps) => {
  return (
    <>
      <div
        className={clsx(
          'grid grid-cols-6 gap-x-2 p-2 md:grid-cols-12 md:p-6 md:gap-x-4 xl:p-8 xl:mx-auto xl:max-w-[1440px]',
          className,
        )}
      >
        {children}
      </div>
      {debug && (
        <div className="fixed w-screen h-screen top-0 opacity-10 z-grid">
          <div className="grid grid-cols-6 gap-x-2 m-2 md:grid-cols-12 md:m-6 md:gap-x-4 xl:m-8">
            <DebugColumn />
            <DebugColumn />
            <DebugColumn />
            <DebugColumn />
            <DebugColumn />
            <DebugColumn />
            <DebugColumn mobile />
            <DebugColumn mobile />
            <DebugColumn mobile />
            <DebugColumn mobile />
            <DebugColumn mobile />
            <DebugColumn mobile />
          </div>
        </div>
      )}
    </>
  )
}
