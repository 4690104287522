export const routePaths = {
  accountSuccess: 'account-success',
  account: 'account',
  assessmentSummary: 'assessment-summary',
  archivedGuide: 'archived-guide',
  dashboard: 'dashboard',
  practice: 'practice',
  forgotPassword: 'forgot-password',
  forgotPasswordConfirm: 'forgot-password/confirm',
  invite: 'invite',
  login: 'login',
  patient: 'patient',
  patients: 'patients',
  patientId: ':treatmentGuideId',
  treatmentGuides: 'treatment-guides',
  treatmentGuideId: ':treatmentGuideId',
  resetPassword: 'reset-password',
  support: 'support',
  treatments: 'treatments',
  treatment: ':id',
  treatmentName: ':treatmentName',
  editTreatment: ':patientId/edit-treatment',
  onboarding: 'onboarding',
  stepNumber: ':stepNumber',
  implantAssessment: 'implant-assessment',
  implantPlacement: 'implant-placement',
  postPlacementReview: 'post-placement-review',
  implantExposure: 'implant-exposure',
  crownImpression: 'crown-impression',
  extraction: 'extraction',
  crownFitting: 'crown-fitting',
  maintenanceReview: 'maintenance-review',
  boneGraft: 'bone-graft',
  sinusLift: 'sinus-lift',
  cbctScan: 'cbct-scan',
  cookieDeclaration: 'cookie-declaration',
  quiz: 'quiz',
  smileQuiz: 'smile-quiz',
  success: 'success',
  error: 'error',
  finance: 'finance',
  treatmentPlan: 'treatment-plan',
  financeConfirmation: 'finance-confirmation',
  financeVerification: 'finance-verification',
  aftercare: 'aftercare',
  clearAlignersAssessment: 'clear-aligners-assessment',
  assessmentScans: 'assessment-scans',
  preparation: 'preparation',
  fittingAppointment: 'fitting-appointment',
  progressReview: 'progress-review',
  progressImpressions: 'progress-impressions',
  finalReview: 'final-review',
  clearAlignersOverview: 'clear-aligners-overview',
  implantsOverview: 'implant-overview',
  patientInvite: 'patient-invite',
  treatmentGuide: 'treatment-guide',
  treatmentPreview: 'treatment/:treatmentId/preview',
  practiceViewTreatmentGuide: 'view-treatment-guide/:treatmentGuideId',
  myGuide: 'my-guide',
  treatmentDefinition: 'my-guide/:treatmentName',
  introduction: 'introduction',
  linkSent: 'link-sent',
  financeCalculator: 'finance-calculator',
  createOrder: 'create-order',
  financeWelcomeBack: 'finance-welcome-back',
  payments: 'payments',
  plans: 'plans',
  marketing: 'marketing',
  checkout: 'checkout',
  plansSuccess: 'plan-success',
} as const

export const apiUrls = {
  practitionerIntercomHash: '/practice/intercom',
  patientIntercomHash: '/patient/intercom',
  patientInfo: (patientId: string) => `/patient/${patientId}`,
  patientList: `/practice/patients`,
  patientUserDetails: '/patient/user/details',
  practitionerList: `/practice/practitioners`,
  patientSignUp: '/patient/signup',
  practiceSignUp: '/practice/signup',
  practitionerSignUp: '/practitioner/signup',
  practitionerSignUpUser: '/practitioner/signup/user',
  practitionerUserDetails: '/practitioner/user/details',
  practitionerUpdateDetails: (practitionerId: string) => `/practitioner/user/${practitionerId}/details`,
  guidePractitionerAdd: '/guide-practitioner',
  guidePractitionerUpdate: (guidePractitionerId: string) => `/guide-practitioner/${guidePractitionerId}/`,
  guidePractitionerGet: `/guide-practitioner/details`,
  practiceGuidePractitionerGet: (treatmentGuideId: string) => `treatment-guide/${treatmentGuideId}/guide-practitioners`,
  practitionerJourneys: '/practitioner/journeys',
  patientJourneyId: (journeyId: string) => `/patient/journey/${journeyId}`,
  practitionerJourneyId: (id: string) => `/practitioner/journey/${id}`,
  practiceDetails: (id: string) => `/practice/${id}/details`,
  practiceDetailsUnprotected: (practiceId: string) =>
    '/practice/details?' +
    new URLSearchParams({
      practiceId,
    }),
  patientQuiz: (type: 'smile' | 'feedback') => `/patient/quiz/${type}`,
  practitionerJourneyTemplate: '/practitioner/journey/template',
  patientSoftSearchKey: '/patient/finance/get-softsearch-key',
  patientFinanceRedirect: '/patient/finance/redirect',
  patientFinanceManual: '/patient/finance/manual',
  patientTreatmentPlan: (filename: string) => `/patient/treatment-plan/${filename}`,
  patientAttachments: `/patient/attachments`,
  patientAvailableInstalments: (practiceId: string) => `/patient/finance/available-instalments/${practiceId}`,
  practiceAvailableInstalments: `/practice/finance/available-instalments/`,
  patientOrderStatus: '/patient/finance/order-status',
  practitionerTreatmentGuide: '/treatment-guide',
  practitionerUpdateIsArchived: '/treatment-guide/update-archived',
  treatmentDefinition: (treatmentName: string) => `/treatment-definition/${treatmentName}`,
  patientTreatmentGuide: (practiceId: string) => `/patient/treatment-guide/${practiceId}`,
  patientTreatmentDefinition: (treatmentName: string) => `/treatment-definition/patient/${treatmentName}`,
  patientCreateOrder: '/patient/order-create',
  patientResetMagicLink: '/treatment-guide/magic-link',
  patientTreatmentGuideMagicLink: (treatmentGuideId: string) => `/treatment-guide/patient/${treatmentGuideId}`,
  practitionerTreatmentPlanUrl: `/practitioner/treatment-plan`,
  practitionerAddTreatmentPlan: (filename: string) => `/practitioner/treatment-plan/${filename}`,
  practitionerAttachmentsUrl: `/practitioner/attachments/generate-urls`,
  practitionerAccountPayments: '/stripe/payments-session',
  practitionerAddAttachments: `/practitioner/attachments`,
  patientUpdateDecision: '/patient/update-decision',
  patientConfirmationSeen: '/patient/confirmation',
  stripeCheckoutSession: '/stripe/checkout-session',
  stripeSubscriptionCheckoutSession: '/stripe/subscription-session',
  updateStripePaymentStatus: '/stripe/payment-status',
  practitionerResendInvite: '/treatment-guide/resend-invite',
  practitionerSendPlan: '/practitioner/send-plan',
  practiceAvailableInstalmentsUnprotected: (orderTotal: string, practiceId: string) =>
    '/practice/finance/available-instalments?' +
    new URLSearchParams({
      practiceId,
      orderTotal,
    }),
} as const

const PRACTICES_PATH = '/practices'
const PRACTITIONERS_PATH = '/practitioners'
const USERS_PATH = '/users'
const USER_PATH = '/user'
const AUTH_PATH = '/auth'
const MEMBERS_PATH = '/members'
const TREATMENT_GUIDES_PATH = '/treatment-guides'
const TREATMENT_DEFINITIONS_PATH = '/treatment-definitions'
const S3_PATH = '/s3'
const PATIENT_OVERVIEW_PATH = '/patient-overview'
const DIVIDE_BUY_PATH = '/dividebuy'
const REDIRECT_PATH = '/redirect'
const MAGIC_LINK_PATH = '/magic-link'
const SOFT_SEARCH_PATH = '/soft-search'
const AVAILABLE_INSTALMENTS_PATH = '/available-instalments'
const INTERCOM = '/intercom'
const PRACTICE_PLANS = (practiceId: string) => `/plans?practiceId=${practiceId}`
const PLANS_STRIPE_KEY = '/auth/stripe/checkout-session'

export const newApiUrls = {
  auth: AUTH_PATH,
  practices: PRACTICES_PATH,
  practitioners: PRACTITIONERS_PATH,
  users: USERS_PATH,
  user: USER_PATH,
  members: MEMBERS_PATH,
  treatmentGuides: TREATMENT_GUIDES_PATH,
  treatmentDefinitions: TREATMENT_DEFINITIONS_PATH,
  s3: S3_PATH,
  patientOverview: PATIENT_OVERVIEW_PATH,
  dividebuy: DIVIDE_BUY_PATH,
  redirect: REDIRECT_PATH,
  magicLink: MAGIC_LINK_PATH,
  softSearch: SOFT_SEARCH_PATH,
  availableInstalments: AVAILABLE_INSTALMENTS_PATH,
  intercom: INTERCOM,
  practicePlans: PRACTICE_PLANS,
  plansStripeKey: PLANS_STRIPE_KEY,
}

export const contentUrl = import.meta.env.DEV ? 'https://app.dev.boostbrush.co.uk' : window.location.origin

export const newApiBaseUrl = `${import.meta.env.VITE_BOOST_BACKEND_URL}/v1`
