import { Size } from '@library/types'

export const getTabClasses = (size: Size) => {
  switch (size) {
    case 'small':
      return 'gap-x-1'
    case 'medium':
      return 'gap-x-1.5'
    case 'large':
      return 'gap-x-2'
  }
}
