import { ChangeEvent, forwardRef, InputHTMLAttributes, ReactNode, WheelEvent } from 'react'
import { BadgeAppearance, ErrorText } from '@library/components/atoms'
import { IconName } from '@library/components/atoms/Icon/utils'
import { InputContainer, Label } from '@library/components/molecules'
import { Size } from '@library/types'
import clsx from 'clsx'

import { errorClasses, inputClasses, inputDisabledClasses } from './classes'
import { getInputSizeClasses, preventInvalidChars, preventPasteNegative } from './utils'
export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  testId?: string
  inputSize: 'small' | 'medium' | 'large'
  labelText?: string
  labelBadge?: string
  labelBadgeAppearance?: BadgeAppearance
  helperText?: string
  errorText?: string
  leftIcon?: IconName
  rightIcon?: IconName
  labelSize?: Size
  isPositiveOnly?: boolean
  preventInvalidCharacters?: boolean
  tooltip?: ReactNode
  preventNumericValues?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      testId,
      className,
      inputSize,
      errorText,
      helperText,
      labelText,
      labelBadge,
      labelBadgeAppearance,
      leftIcon,
      rightIcon,
      labelSize = 'medium',
      isPositiveOnly,
      preventInvalidCharacters,
      tooltip,
      preventNumericValues,
      onChange,
      ...props
    },
    ref,
  ) => {
    const sizeClasses = getInputSizeClasses(inputSize)
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (preventNumericValues) {
        event.target.value = event.target.value.replace(/[0-9]/g, '')
      }
      onChange?.(event)
    }
    return (
      <div className="flex flex-col gap-[0.625rem] relative">
        {(labelText || helperText) && (
          <Label
            htmlFor={props.name}
            disabled={props.disabled}
            appearance="secondary"
            labelText={labelText}
            helperText={helperText}
            labelBadge={labelBadge}
            labelBadgeAppearance={labelBadgeAppearance}
            size={labelSize}
            labelStrong={false}
            tooltip={tooltip}
          />
        )}
        <InputContainer leftIcon={leftIcon} rightIcon={rightIcon} inputSize={inputSize}>
          <input
            ref={ref}
            data-testid={testId}
            className={clsx(
              className,
              inputClasses,
              sizeClasses,
              props.disabled && inputDisabledClasses,
              errorText && errorClasses,
              leftIcon ? (inputSize === 'small' ? 'pl-8' : 'pl-10') : '',
              rightIcon ? (inputSize === 'small' ? 'pr-8' : 'pr-10') : '',
            )}
            {...props}
            onChange={handleOnChange}
            onKeyDown={preventInvalidCharacters ? preventInvalidChars : undefined}
            onWheel={(e: WheelEvent<HTMLInputElement>) => (e.currentTarget as HTMLInputElement).blur()}
            onPaste={isPositiveOnly ? preventPasteNegative : undefined}
          />
        </InputContainer>
        {errorText && <ErrorText icon="info" text={errorText} />}
      </div>
    )
  },
)

Input.displayName = 'Input'
