import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import '@shared/i18n/config'

import App from './App'

import './index.css'

async function startBrowserMockWorker() {
  if (import.meta.env.DEV && import.meta.env.VITE_USE_MSW === 'true') {
    const browserMock = await import('./mocks/browser')
    browserMock.worker.start({
      onUnhandledRequest: ({ method, url }) => {
        if (!url.pathname.startsWith(import.meta.env.BASE_URL)) {
          throw new Error(`Unhandled ${method} request to ${url}`)
        }
      },
    })
  }
}

// prettier-ignore
startBrowserMockWorker();

// prettier-ignore
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID || '',
        context: {
      key: 'anonymous',
    },
    options: {
      sendEvents: false,
    },
  })

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <LDProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LDProvider>
    </React.StrictMode>,
  )
})()
