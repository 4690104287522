import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import translation from './en-GB/translation.json'

// NOTE: see link below
// https://github.com/i18next/react-i18next/blob/master/example/react-typescript/simple/src/i18n/config.ts
// https://react.i18next.com/guides/quick-start
// https://www.i18next.com/overview/typescript
export const resources = {
  'en-GB': {
    translation,
  },
} as const

export const defaultNS = 'translation'

i18n.use(initReactI18next).init({
  lng: 'en-GB',
  debug: import.meta.env.DEV && import.meta.env.MODE !== 'test',
  resources,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  returnNull: false,
})

export default i18n
