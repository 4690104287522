import { NavLink } from 'react-router-dom'
import { t } from 'i18next'

import PracticeLogo from '@practice/components/PracticeLogo'
import { Icon } from '@shared/components/Icon'
import { Typography } from '@shared/components/Typography'
import { useAuth } from '@shared/stores/authStore'
import { routePaths } from '@shared/utils/urls'

type Props = {
  patientName: string
  logoUrl: string
}
export const Navigation = ({ patientName, logoUrl }: Props) => {
  const { logout } = useAuth()

  return (
    <nav data-testid="navigation">
      <div className="xl:p-8 xl:mx-auto xl:max-w-[1440px] p-2 md:p-6">
        <div className="grid grid-cols-7 gap-x-2 md:gap-x-4 items-center">
          <div className="flex gap-4 items-center">
            <NavLink to={`/${routePaths.patient}/${routePaths.dashboard}`}>
              <PracticeLogo className="h-[70px]" imagePath={logoUrl} />
            </NavLink>
          </div>
          <ul className="flex justify-end col-span-3 w-full gap-[5.5rem]">
            <li>
              <NavLink to={`/${routePaths.patient}/${routePaths.dashboard}`}>
                <Typography variant="l16" element="span">
                  {t('patient.navigation.myJourney')}
                </Typography>
              </NavLink>
            </li>
            <li>
              <NavLink to={`/${routePaths.patient}/${routePaths.dashboard}/${routePaths.support}`}>
                <Typography variant="l16" element="span">
                  {t('patient.navigation.support')}
                </Typography>
              </NavLink>
            </li>
          </ul>
          <ul className="flex items-center col-span-3 justify-end gap-8">
            <li className="whitespace-nowrap cursor-pointer" onClick={logout}>
              <Typography variant="l16" element="span" className="underline text-primary-main">
                {t('patient.navigation.logout')}
              </Typography>
            </li>
            <li className="max-w-[15rem] break-all">
              <NavLink
                to={`/${routePaths.patient}/${routePaths.dashboard}/${routePaths.account}`}
                className="grid grid-flow-col items-center gap-2"
              >
                <Typography variant="l16" element="span">
                  {patientName}
                </Typography>
                <Icon name="user" className="stroke-primary-dark" />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
